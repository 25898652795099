
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'
	import WangEditor from "../components/WangEditor"
	import {
		readFileContent,
		getUUID
	} from "../util/index.js"
	import {
		ElMessage
	} from 'element-plus'
	import iconfont from '../components/iconfont.vue';
	export default {
		data() {
			return {
				isloading: false,
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				brands: [],
				groups: [],
				pdtid: 0,
				isClear: false,
				lbpos: "right",
				addproduct: {
					ID: '',
					PdtName: '',
					PdtBrief: '',
					PdtCode: '',
					ImageUrl: '',
					MainImgList: [],
					BrandID: '0',
					SelGroupIDs: [],
					Tags: [],
					Author: '',
					Source: '',
					FromUrl: '',
					Status: 1,
					Stock: 0,
					MPrice: 0,
					RPrice: 0,
					PdtDetail: '',
					CreatorOPID: 0,
					ModifyOPID: 0,
				},
				CropperOpt: 1,	//1：缩略图, 2：主图
				MainImgUploading: false,
				MainImgList: [],
				ImageUrl: '',
				SelCateTxt: [],
				showtaginput: false,
				TagInputVal: '',
				props: {
					label: 'Name',
					children: 'LHSTChildren',
				},
				count: 1,
				checkeddata: [],
				croperimg: '',
				showcroperdlg: false,
				aspectRatio: 0,
				cpage: 1,
				dialogVisible: false,
			}
		},
		watch: {
			'addproduct.SelGroupIDs': {
				handler() {
					this.UpdateSelCateTxt();
				},
				deep: true
			},
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						// 打印clientWidth变化的值
						//console.log(that.clientWidth)
						if (that.clientWidth <= 640)
							that.lbpos = 'top';
						else
							that.lbpos = "right";
						that.timerw = false
					}, 400)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						// 打印clientHeight变化的值
						//console.log(that.clientHeight)
						that.timerh = false
					}, 400);
				}
			},
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.AddProduct;
		},
		methods: {
			RemoveMainImg(mimg) {
				console.log(mimg);
				for(var imi=0;imi<this.MainImgList.length;++imi) {
					if(this.MainImgList[imi].uuid == mimg.uuid) {
						this.MainImgList.splice(imi, 1);
						break;
					}
				}
			},
			onFileSel1(filelist) {
				if (filelist.length <= 0)
					return;

				let file = filelist[0];

				if (!file.name)
					return;
					
				this.CropperOpt = 2;

				var index = file.name.lastIndexOf("."); //获取最后一个.的位置
				var ext = file.name.substr(index + 1).toLowerCase(); //获取后缀
				var pattern = /^(jpg|jpeg|png|gif)$/;
				if (!pattern.test(ext)) {
					ElMessage({
						message: this.PAGELANG.wjgsbzc,
						type: 'error',
					});
					return false;
				}

				readFileContent(file, 'dataUrl').then((contents) => {
					this.croperimg = contents;
					this.showcroperdlg = true;
					this.$refs.uploader1.value = '';
				});
			},
			onImgRemoved(file, fileList) {
				//fileList.splice(file);
				console.log(file, fileList, this.MainImgList);
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			CropImage() {
				if(!this.showcroperdlg)
					return;
					
				this.showcroperdlg = false;
				if(this.CropperOpt == 1) {
					this.addproduct.ImageUrl = cropper.getDataURL();
					this.ImageUrl = this.addproduct.ImageUrl;
				} else {
					let dataurl = cropper.getDataURL();
					this.MainImgList.push({
						data: dataurl,
						loading: true,
						uuid: getUUID()
					});
					this.addproduct.MainImgList.push(dataurl);
					
					this.UploadMainImg(this.MainImgList[this.MainImgList.length - 1]);
				}
			},
			UploadMainImg(data) {
				console.log(this.MainImgList);
				
				this.axios.post(this.actions.uploadmainimg, data).then((response) => {
					console.log(response.data);
					if (response.data.retinfo == "ok") {
						this.$message(this.LANG.system.caozuochenggong);
						this.addproduct.MainImgList = [];
						for(var imil = 0; imil < this.MainImgList.length;++imil) {
							if(this.MainImgList[imil].uuid == data.uuid) {
								this.MainImgList[imil].url = response.data.saveurl;
								this.MainImgList[imil].loading = false;
								//console.log(imil + "is checked!");
							}
						}
					} else {
						this.$message(this.PAGELANG[response.data.retinfo]);
					}
				})
			},
			onFileSel(filelist) {
				if (filelist.length <= 0)
					return;

				let file = filelist[0];

				if (!file.name)
					return;
					
				this.CropperOpt = 1;

				var index = file.name.lastIndexOf("."); //获取最后一个.的位置
				var ext = file.name.substr(index + 1).toLowerCase(); //获取后缀
				var pattern = /^(jpg|jpeg|png|gif)$/;
				if (!pattern.test(ext)) {
					ElMessage({
						message: this.PAGELANG.wjgsbzc,
						type: 'error',
					});
					return false;
				}

				readFileContent(file, 'dataUrl').then((contents) => {
					this.croperimg = contents;
					this.showcroperdlg = true;
					this.$refs.uploader.value = '';
				});

			},
			onExecCrop() {
				this.imageurl = cropper.getDataURL();
				this.showimgeditor = false;
				this.himgupdated = true;
			},
			UpdateSelCateTxt() {
				let nodes = this.$refs.catetree.getCheckedNodes();
				this.SelCateTxt = [];

				for (var key in nodes) {
					this.SelCateTxt.push(nodes[key].Name);
				}
				//console.log(this.SelCateTxt);
			},
			handleCheckChange(data, checked, indeterminate) {
				console.log(data, checked, indeterminate);
				if (checked) {
					if(this.addproduct.SelGroupIDs.indexOf(data.ID) < 0)
						this.addproduct.SelGroupIDs.push(data.ID);
					console.log(this.addproduct.SelGroupIDs.indexOf(data.ID));
				}
				else if (this.addproduct.SelGroupIDs.indexOf(data.ID) >= 0)
					this.addproduct.SelGroupIDs.splice(this.addproduct.SelGroupIDs.indexOf(data.ID), 1);

				//console.log(this.addproduct.SelGroupIDs);
			},
			handleNodeClick(data) {
				console.log(data)
			},
			onSubmit() {
				this.addproduct.MainImgList = [];
				
				for(var imil=0;imil<this.MainImgList.length;++imil) {
					if(this.MainImgList[imil].loading) {
						ElMessage({
							message: this.PAGELANG.ztsczwftj,
							type: 'error',
						});
						break;
					} else {
						this.addproduct.MainImgList.push(this.MainImgList[imil].url);
					}
				}
				console.log(this.addproduct);
				this.axios.post(this.actions.savepdt, this.addproduct).then((response) => {
					console.log(response.data);
					if (response.data.retinfo == "ok") {
						this.$message(this.LANG.system.caozuochenggong);
						this.$router.go(-1);
					} else {
						this.$message(this.PAGELANG[response.data.retinfo]);
					}
				})

			},
			onNewTag() {
				this.showtaginput = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus()
				})
			},
			handleInputConfirm() {
				const TagInputVal = this.TagInputVal
				if (TagInputVal) {
					this.addproduct.Tags.push(TagInputVal)
				}
				this.showtaginput = false
				this.TagInputVal = ''
			},
			onCloseTag(t) {
				this.addproduct.Tags.splice(this.addproduct.Tags.indexOf(t), 1)
			},
			GoBack() {
				this.$router.go(-1);
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			},
			RefreshBrands() {
				this.isloading = true;
				//console.log(this.actions.getallbrand);
				this.axios.get(this.actions.getallbrand, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.isloading = false;
					this.brands = response.data;
					//console.log(this.groups);
					this.brands.unshift({
						ID: '0',
						Name: this.PAGELANG.qxzpp,
						Remark: this.PAGELANG.qxzpp,
						Status: true,
						ParentID: 0,
						RootID: 0,
					});
				});
			},
			RefreshGroups() {
				this.isloading = true;
				this.axios.get(this.actions.grouptree, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.isloading = false;
					this.groups = response.data;
					//console.log(this.groups);
					/*this.groups.unshift({
						ID: '0',
						Name: '所有分类',
						Remark: '所有分类',
						Status: true,
						ParentID: 0,
						RootID: 0,
					});*/
				});
			},
			GetPdtInfo() {
				if (!this.pdtid)
					return false;

				this.axios.get(this.actions.getpdtinfo + '&stamp=' + (new Date()).getTime(), {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: this.pdtid,
					}
				}).then((response) => {
					let pdtinfo = response.data.pdtinfo;
					this.addproduct.ID = pdtinfo.ID;
					this.addproduct.PdtName = pdtinfo.PdtName;
					this.addproduct.PdtBrief = pdtinfo.PdtBrief;
					this.addproduct.PdtDetail = pdtinfo.PdtDetail;
					this.addproduct.PdtCode = pdtinfo.PdtCode;
					this.addproduct.BrandID = pdtinfo.BrandID;
					this.addproduct.SelGroupIDs = response.data.Groups;
					console.log(response.data);
					if(pdtinfo.Tags)
						this.addproduct.Tags = pdtinfo.Tags;
					this.addproduct.PdtDetail = pdtinfo.PdtDetail;
					this.addproduct.MPrice = pdtinfo.MPrice;
					this.addproduct.RPrice = pdtinfo.RPrice;
					this.addproduct.ImageUrl = pdtinfo.PdtThumb;
					this.addproduct.Status = pdtinfo.Status;
					this.addproduct.Stock = pdtinfo.Stock;
					
					//let mainimglst = JSON.parse(pdtinfo.PdtImages);
					
					for(var miil=0;miil<pdtinfo.PdtImages.length;++miil) {
						this.MainImgList.push({
							uuid: getUUID(),
							loading: false,
							url: pdtinfo.PdtImages[miil]
						});
					}
					
					
					if (this.addproduct.ImageUrl)
						this.ImageUrl = this.apibase + this.addproduct.ImageUrl;
					//console.log(this.ImageUrl);
					console.log(pdtinfo);
					
					let that = this;
					setTimeout(function() {
						that.UpdateSelCateTxt();
					}, 500);
				});
			},
			DescChange(val) {
				//console.log(val)
				this.addproduct.PdtDetail = val;
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		mounted() {
			if (this.$route.query.pdtid)
				this.pdtid = this.$route.query.pdtid;
			if (this.$route.query.cpage)
				this.cpage = this.$route.query.cpage;
			//console.log(this.pdtid);
			if (this.clientWidth <= 640)
				this.lbpos = 'top';
			else
				this.lbpos = "right";
			this.GetPdtInfo();
			this.RefreshBrands();
			this.RefreshGroups();
		},
		components: {
			iconfont,
			WangEditor,
			VuePictureCropper
		}
	}
